export const PRACTICE_SCHEMA = 2;
export const SENTENCE_SCHEMA = 1;
export const MINIMUM_PRACTICE_SCHEMA = 2;
export const AVAILABLE_LANGS = ['en-US', 'it-IT', 'ko-KO', 'fa-IR', 'zh-CN'];
export const LANG_MAP: Record<string, string> = {
  'es-MX': 'Spanish (Mexico)',
  'en-US': 'English (US)',
  'it-IT': 'Italian',
  'ko-KO': 'Korean',
  'fa-IR': 'Farsi',
  'zh-CN': 'Chinese Simplified',
};

export const PROCESS_PROMPTS = {
  POS: `
1-Identify Parts of Speech: For each word in the input sentence, determine its part of speech (e.g., noun, verb, adjective) based on standard grammatical rules.
2-Map to Part of Speech Codes: Using the provided L1 mapping table below, convert each part of speech to its corresponding code:

S: Noun (Sustantivo)
X: Auxiliary (Auxiliar)
V: Verb (Verbo)
A: Adjective (Adjetivo)
B: Adverb (Adverbio)
Y: Pronoun (Pronombre)
N: Numeral (Numeral)
C: Conjunction (Conjunción)
I: Interjection (Interjección)
L: Article (Artículo)
T: Participle (Participio)

3-Format: For each word, prepend the corresponding letter code from the table to the word, followed by a colon (:) and the word itself. Example: L:El, S:teléfono.
4-Structure the Output: For each input sentence, create a JSON object where the key is the sentence number (starting from 0) and the value is an array of the part-of-speech-tagged words.
5-Create the Final JSON Array: Combine all sentence objects into a single JSON array. Ensure that the array contains as many elements as the number of input sentences.
6-Verify: Ensure that the number of elements in the output JSON array matches the number of input sentences.
7-Give me the output only in a textbox, no explanation, and the JSON in condensed format.

Example input:
0>El teléfono suena constantemente.
1>Te había dicho que si comías demasiado te sentirías mal.
2>Las arañas tejen telarañas.

Expected output:
[{"0": ["L:El", "S:teléfono", "V:suena", "A:constantemente"]},
  {"1": ["Y:Te", "V:había dicho", "C:que", "C:si", "V:comías", "A:demasiado", "Y:te", "V:sentirías", "A:mal"]},
  {"2": ["L:Las", "S:arañas", "V:tejen", "S:telarañas"]}]

Now do this for the following sentences:
`,
  T: `
1-Translate Mexican Spanish sentences into DESTINATION_LANGUAGE
2-Give me the output only in a textbox, no explanation, and the JSON in condensed format.
2- Use the schema below for json.

Schema:
- id: Sequential identifier for each sentence. They are given in the input sentences.
- t: Translated text.
- m (optional): Metadata with two subcategories:
  - h (Hints): An array addressing translation ambiguities (singular/plural, gender, Tú, usted, etc.). Always include hits for Tú, usted, ustedes, ellos, ellas. Format: [index, Spanish word, index, translated word, type]. 
    Possible hint types:
    - P: Plural
    - S: Singular
    - M: Masculino
    - F: Femenino
    - PM: Plural Masculino
    - PF: Plural Femenino
    - SM: Singular Masculino
    - SF: Singular Femenino
  - s (Synonyms): An array of acceptable Spanish synonyms. Format: [index, original Spanish word, index, synonym].

Only include 'h' or 's' if necessary. 

Now do this for the following sentences:
  `,
  S: '',
  H: '',
};
