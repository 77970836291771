import React, { useState } from 'react';
import { Alert, Button, Col, Container, ListGroup, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import DragDropUploadPage from '../../components/DragDropUpload';
import { useGetAllArtworksQuery } from '../../graphql/server-graphql-schema';

const ArtworkListPage: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useGetAllArtworksQuery({
    variables: {
      page: 1, // default page number
      limit: 10, // default limit per page
      lang: 'en', // default language
    },
    fetchPolicy: 'network-only',
  });

  const [selectedArtworkId, setSelectedArtworkId] = useState<string | null>(null);

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <span className="sr-only">Loading...</span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {error.message}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Row className="mb-4">
        <Col>
          <h2>Artwork List</h2>
        </Col>
        <Col className="text-end">
          <Button variant="primary" onClick={() => navigate('/create-artwork')}>
            Add New Artwork
          </Button>
        </Col>
      </Row>
      <ListGroup>
        {data?.getAllArtworks?.artworks.map((artwork) => {
          // Retrieve the localized content for the specified language
          const localeContent = artwork.text?.find((item) => item?.locale === 'en');
          const descriptionText = localeContent?.description?.text?.[0] || 'No description available';

          return (
            <ListGroup.Item key={artwork.id}>
              <Row>
                <Col>
                  <strong>{artwork.name}</strong> - {artwork.type}
                  <div>By: {artwork.maker?.name}</div>
                  <div className="text-muted mt-2">
                    <small>{descriptionText}</small>
                  </div>
                </Col>
                <Col className="text-end">
                  <Button
                    variant="secondary"
                    onClick={() => setSelectedArtworkId((prev) => (prev === artwork.id ? null : artwork.id))}
                  >
                    {selectedArtworkId === artwork.id ? 'Close Media Upload' : 'Upload Media'}
                  </Button>
                </Col>
              </Row>
              {selectedArtworkId === artwork.id && (
                <Row className="mt-3">
                  <Col>
                    <DragDropUploadPage artworkId={artwork.id} />
                  </Col>
                </Row>
              )}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Container>
  );
};

export default ArtworkListPage;
