import React, { createContext, useContext, useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

interface ToastOptions {
  message: string;
  variant: 'success' | 'error';
  autohide?: boolean;
}

interface ToastContextProps {
  showToast: (options: ToastOptions) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toastOptions, setToastOptions] = useState<ToastOptions | null>(null);
  const [show, setShow] = useState(false);

  const showToast = (options: ToastOptions) => {
    setToastOptions(options);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (toastOptions) {
      setShow(true);
    }
  }, [toastOptions]);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toastOptions && (
        <ToastContainer position="top-end" className="p-3">
          <Toast
            onClose={handleClose}
            show={show}
            delay={3000}
            autohide={toastOptions.autohide ?? true}
            style={{
              backgroundColor: toastOptions.variant === 'success' ? '#4caf50' : '#f44336',
              color: 'white',
              position: 'relative',
            }}
          >
            {!toastOptions.autohide && (
              <button
                onClick={handleClose}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'white',
                  fontSize: '1.5rem',
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <FaTimes />
              </button>
            )}
            <Toast.Body className={toastOptions.autohide ? '' : 'mt-5'}>{toastOptions.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </ToastContext.Provider>
  );
};
