import React from 'react';
import { Helmet } from 'react-helmet-async';

const HomePage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Art Hista: Your museum guide</title>
      </Helmet>
      <div className="container">
        <div className="col-12">
          <div className="row flex-lg-row-reverse">
            <div className="col-12 col-lg-7" style={{ textAlign: 'right' }}>
              <div className="d-none d-lg-block" style={{ marginTop: '8rem' }}></div>
              <img
                srcSet={`${process.env.PUBLIC_URL}/images/people-talking1_low.jpg 500w,
                ${process.env.PUBLIC_URL}/images/people-talking1_medium.jpg 700w,
                ${process.env.PUBLIC_URL}/images/people-talking1_high.jpg 1200w`}
                sizes="(max-width: 600px) 300px,
               (max-width: 1200px) 600px,
               1200px"
                src={`${process.env.PUBLIC_URL}/images/people-talking1_high.jpg`}
                className="img-fluid"
                alt=""
                style={{ maxWidth: '100%', height: 'auto' }} // Ensure the image maintains aspect ratio
                width="700"
                height="500"
                loading="lazy"
              />
            </div>
            <div className="col-lg-5 mt-5 text-center">
              <h1 className="display-5 fw-bold lh-1 mb-3 mt-5 mt-md-0" style={{ fontSize: 88 }}>
                Art
                <span className="fst-italic" style={{ color: '#6c757d', opacity: 0.7 }}>
                  Hista
                </span>
              </h1>
              <p className="lead mt-5">
                Experience museums like never before with ArtHista. Learn about the artwork in your own language and dive deep
                into the artist's story, historical context, motivations, and controversies—making every visit truly
                unforgettable.
                <br />
                <br />
                Capture your favorite exhibits and share them effortlessly, making your museum experience memorable and easy to
                relive.
              </p>
              <div className="mt-5">
                <h4 className="mb-5">Coming soon to your phone!</h4>
                <div className="text-muted" style={{ opacity: 0.5 }}>
                  <a
                    href="https://apps.apple.com/eg/app/lengua-learn-spanish/id6469734069"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-inline-block mb-3"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`}
                      alt="Download on the App Store"
                      style={{ height: '60px', maxWidth: '100%' }}
                    />
                  </a>

                  <div className="mt-4">
                    <a
                      href="https://play.google.com/apps/testing/app.lengua"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-inline-block mb-3"
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/PreRegisterOnGooglePlay_Badge_Web_color_english.png.png`}
                        alt="Get it on Google Play"
                        style={{ height: '60px', maxWidth: '100%' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
