import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import {
  MediaType,
  useGenerateSignedUploadUrlLazyQuery,
  useProcessUploadedMediaMutation,
} from '../graphql/server-graphql-schema';

const MAX_IMAGE_SIZE_MB = 1; // Maximum image size in MB before resizing
const MAX_IMAGE_WIDTH = 1024; // Maximum width for resized image
const MAX_IMAGE_HEIGHT = 1024; // Maximum height for resized image

type DragDropUploadProps = {
  artworkId: string;
};

const DragDropUploadPage: React.FC<DragDropUploadProps> = ({ artworkId }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadedMedia, setUploadedMedia] = useState<any>(null);

  const [generateSignedUploadUrl] = useGenerateSignedUploadUrlLazyQuery();
  const [processUploadedMedia] = useProcessUploadedMediaMutation();

  // Resize the image if it exceeds the maximum size
  const resizeImage = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target?.result as string;
      };

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const scale = Math.min(
          MAX_IMAGE_WIDTH / img.width,
          MAX_IMAGE_HEIGHT / img.height,
          1, // Do not upscale if the image is smaller than max dimensions
        );

        canvas.width = img.width * scale;
        canvas.height = img.height * scale;

        if (ctx) {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Failed to resize image'));
              }
            },
            file.type,
            0.8, // Adjust quality (0.8 = 80%)
          );
        } else {
          reject(new Error('Failed to create canvas context'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Failed to read image file'));
      };

      reader.readAsDataURL(file);
    });
  };

  // Upload the file to the signed URL
  const uploadToSignedUrl = async (url: string, file: File | Blob) => {
    await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type || 'application/octet-stream',
      },
      body: file,
    });
  };

  // Notify server to process the uploaded media
  const handleProcessUploadedMedia = async (fileName: string) => {
    const result = await processUploadedMedia({
      variables: {
        input: {
          artworkId,
          fileName,
          type: MediaType.Image,
        },
      },
    });

    if (result.data?.processUploadedMedia) {
      setUploadedMedia(result.data.processUploadedMedia);
    } else {
      throw new Error('Failed to process uploaded media');
    }
  };

  // Main handler for file upload
  const handleFileUpload = async (file: File | Blob) => {
    const originalFileName = (file instanceof File ? file.name : 'resized-image.jpg').replace(/\s+/g, '-').toLowerCase();
    const { data } = await generateSignedUploadUrl({
      variables: {
        input: {
          fileName: originalFileName,
          contentType: file.type || 'application/octet-stream',
        },
      },
    });

    if (data?.generateSignedUploadUrl) {
      const { signedUrl, fileName } = data.generateSignedUploadUrl;

      // Upload the file to Google Cloud Storage
      await uploadToSignedUrl(signedUrl, file);

      // Notify the server to process the uploaded media
      await handleProcessUploadedMedia(fileName);
    } else {
      throw new Error('Failed to generate signed upload URL');
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    setError(null);

    if (acceptedFiles.length === 0) {
      setError('No files selected');
      return;
    }

    const file = acceptedFiles[0];
    let fileToUpload: File | Blob = file;

    try {
      if (file.type.startsWith('image/') && file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
        fileToUpload = await resizeImage(file);
      }

      setUploading(true);

      await handleFileUpload(fileToUpload);

      setUploading(false);
    } catch (uploadError) {
      setUploading(false);
      setError('Failed to upload file');
      console.error(uploadError);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 1,
  });

  return (
    <Container className="mt-5">
      <Row className="mb-4">
        <Col>
          <h2>Upload Image</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div {...getRootProps()} style={styles.uploadArea}>
            <input {...getInputProps()} />
            {!uploading && <p>Drag and drop a file here, or click to select one</p>}
            {uploading && <p>Uploading...</p>}
            {error && <p style={styles.errorText}>{error}</p>}
          </div>
        </Col>
        <Col md={6}>
          {uploadedMedia && (
            <div style={styles.previewContainer}>
              <h5>Uploaded Media</h5>
              <img src={uploadedMedia.url} alt="Uploaded Media" style={styles.previewImage} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DragDropUploadPage;

const styles = {
  uploadArea: {
    border: '2px dashed #cccccc',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center' as 'center',
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
  },
  previewContainer: {
    textAlign: 'center' as 'center',
  },
  previewImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    marginTop: '10px',
  },
};
