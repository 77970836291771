import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type AdminSettings = {
  __typename?: 'AdminSettings';
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AdminSettingsInput = {
  data: Scalars['JSON']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AppSettings = {
  __typename?: 'AppSettings';
  availableLangs: Array<Scalars['String']['output']>;
  contentFiles: Array<Scalars['String']['output']>;
  userSettings?: Maybe<UserSettings>;
};

export type Artwork = {
  __typename?: 'Artwork';
  createdAt: Scalars['String']['output'];
  dateStarted?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location: Location;
  maker: Maker;
  meta?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  text?: Maybe<Array<Maybe<LocalizedTextContent>>>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  user: User;
};

export type ArtworkPagination = {
  __typename?: 'ArtworkPagination';
  artworks: Array<Artwork>;
  hasMore: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
};

export type Audio = {
  __typename?: 'Audio';
  url?: Maybe<Scalars['String']['output']>;
  voiceName?: Maybe<Scalars['String']['output']>;
};

export type AudioInput = {
  url?: InputMaybe<Scalars['String']['input']>;
  voiceName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateArtworkInput = {
  dateStarted?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['ID']['input'];
  makerId: Scalars['ID']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  text?: InputMaybe<Array<InputMaybe<LocalizedTextContentInput>>>;
  type: Scalars['String']['input'];
};

export type CreateLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateMakerInput = {
  birthPlace?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  dateOfDeath?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  text?: InputMaybe<Array<InputMaybe<LocalizedTextContentInput>>>;
};

export type CreateTextContentInput = {
  content: Scalars['String']['input'];
  entityType: Scalars['String']['input'];
  fkId: Scalars['ID']['input'];
  languageCode: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type FieldLengthContent = {
  __typename?: 'FieldLengthContent';
  audio?: Maybe<Array<Maybe<Audio>>>;
  text?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FieldLengthContentInput = {
  audio?: InputMaybe<Array<InputMaybe<AudioInput>>>;
  text?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GenerateSignedUploadUrlInput = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type LocalizedTextContent = {
  __typename?: 'LocalizedTextContent';
  description?: Maybe<FieldLengthContent>;
  locale: Scalars['String']['output'];
  name?: Maybe<FieldLengthContent>;
};

export type LocalizedTextContentInput = {
  description?: InputMaybe<FieldLengthContentInput>;
  locale: Scalars['String']['input'];
  name?: InputMaybe<FieldLengthContentInput>;
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  user: User;
};

export type LocationPagination = {
  __typename?: 'LocationPagination';
  hasMore: Scalars['Boolean']['output'];
  locations: Array<Location>;
  total: Scalars['Int']['output'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jwt: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profile?: Maybe<Scalars['JSON']['output']>;
  role: Scalars['String']['output'];
};

export type Maker = {
  __typename?: 'Maker';
  birthPlace?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  dateOfDeath?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  text?: Maybe<Array<Maybe<LocalizedTextContent>>>;
  updatedAt: Scalars['String']['output'];
  user: User;
};

export type MakerPagination = {
  __typename?: 'MakerPagination';
  hasMore: Scalars['Boolean']['output'];
  makers: Array<Maker>;
  total: Scalars['Int']['output'];
};

export type Media = {
  __typename?: 'Media';
  anchorText?: Maybe<Scalars['String']['output']>;
  artwork: Artwork;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  type: MediaType;
  updatedAt: Scalars['String']['output'];
  url: Scalars['String']['output'];
  user: User;
};

export enum MediaType {
  Image = 'IMAGE',
  Url = 'URL',
  Video = 'VIDEO'
}

export type Mutation = {
  __typename?: 'Mutation';
  anonymizeUser: User;
  createArtwork: Artwork;
  createLocation: Location;
  createMaker: Maker;
  createTextContent: TextContent;
  deleteAdminSettings?: Maybe<Scalars['Boolean']['output']>;
  deleteArtwork: Scalars['Boolean']['output'];
  deleteLocation: Scalars['Boolean']['output'];
  deleteMaker: Scalars['Boolean']['output'];
  deleteTextContent: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  insertAdminSettings?: Maybe<AdminSettings>;
  login: LoginResponse;
  processUploadedMedia: Media;
  syncSettings?: Maybe<AppSettings>;
  updateArtwork: Artwork;
  updateLocation: Location;
  updateMaker: Maker;
  updateTextContent: TextContent;
  updateUser: User;
  upsertAdminSettings?: Maybe<AdminSettings>;
};


export type MutationAnonymizeUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateArtworkArgs = {
  input: CreateArtworkInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateMakerArgs = {
  input: CreateMakerInput;
};


export type MutationCreateTextContentArgs = {
  input: CreateTextContentInput;
};


export type MutationDeleteAdminSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteArtworkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMakerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTextContentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationInsertAdminSettingsArgs = {
  input: AdminSettingsInput;
};


export type MutationLoginArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationProcessUploadedMediaArgs = {
  input: ProcessUploadedMediaInput;
};


export type MutationSyncSettingsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userSettings?: InputMaybe<UserSettingsInput>;
};


export type MutationUpdateArtworkArgs = {
  id: Scalars['ID']['input'];
  input: UpdateArtworkInput;
};


export type MutationUpdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLocationInput;
};


export type MutationUpdateMakerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMakerInput;
};


export type MutationUpdateTextContentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTextContentInput;
};


export type MutationUpdateUserArgs = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertAdminSettingsArgs = {
  input: AdminSettingsInput;
};

export type ProcessUploadedMediaInput = {
  artworkId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  type: MediaType;
};

export type Query = {
  __typename?: 'Query';
  appSettings?: Maybe<AppSettings>;
  generateSignedUploadUrl: SignedUploadUrl;
  getAdminSettings: Array<AdminSettings>;
  getAdminSettingsById?: Maybe<AdminSettings>;
  getAdminSettingsByType: Array<AdminSettings>;
  getAllArtworks: ArtworkPagination;
  getAllLocations: LocationPagination;
  getAllMakers: MakerPagination;
  getArtworkById: Artwork;
  getLocationById: Location;
  getMakerById: Maker;
  getTextContentByFk: Array<TextContent>;
  user: User;
  userReports: UserPagination;
};


export type QueryAppSettingsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGenerateSignedUploadUrlArgs = {
  input: GenerateSignedUploadUrlInput;
};


export type QueryGetAdminSettingsByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAdminSettingsByTypeArgs = {
  type: Scalars['String']['input'];
};


export type QueryGetAllArtworksArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllMakersArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetArtworkByIdArgs = {
  id: Scalars['ID']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetLocationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetMakerByIdArgs = {
  id: Scalars['ID']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTextContentByFkArgs = {
  entityType: Scalars['String']['input'];
  fkId: Scalars['ID']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
  includeReportCount?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryUserReportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort: SortInput;
};

export type SentenceSetSettings = {
  __typename?: 'SentenceSetSettings';
  lastUpdated: Scalars['Int']['output'];
  maxSentencesInSet: Scalars['Int']['output'];
  numCorrectToPass: Scalars['Int']['output'];
};

export type SentenceSetSettingsInput = {
  lastUpdated: Scalars['Int']['input'];
  maxSentencesInSet: Scalars['Int']['input'];
  numCorrectToPass: Scalars['Int']['input'];
  setId: Scalars['String']['input'];
};

export type SentenceSetSettingsMap = {
  __typename?: 'SentenceSetSettingsMap';
  setId: Scalars['String']['output'];
  settings: SentenceSetSettings;
};

export type SignedUploadUrl = {
  __typename?: 'SignedUploadUrl';
  fileName: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type SortInput = {
  direction: Scalars['String']['input'];
  field: Scalars['String']['input'];
};

export type TextContent = {
  __typename?: 'TextContent';
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  entityType: Scalars['String']['output'];
  fkId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  length: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  user: User;
};

export type UpdateArtworkInput = {
  dateStarted?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  makerId?: InputMaybe<Scalars['ID']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Array<InputMaybe<LocalizedTextContentInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMakerInput = {
  birthPlace?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  dateOfDeath?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Array<InputMaybe<LocalizedTextContentInput>>>;
};

export type UpdateTextContentInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<Scalars['String']['input']>;
  fkId?: InputMaybe<Scalars['ID']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  artworks?: Maybe<Array<Artwork>>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  makers?: Maybe<Array<Maker>>;
  media?: Maybe<Array<Media>>;
  name: Scalars['String']['output'];
  profile?: Maybe<Scalars['JSON']['output']>;
  role: Scalars['String']['output'];
  updatedDate: Scalars['String']['output'];
};

export type UserPagination = {
  __typename?: 'UserPagination';
  hasMore: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
  users: Array<User>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  sentenceSetSettings: Array<SentenceSetSettingsMap>;
};

export type UserSettingsInput = {
  sentenceSetSettings: Array<SentenceSetSettingsInput>;
};

export type AdminSettingsFieldsFragment = { __typename?: 'AdminSettings', id: string, name: string, type: string, data: any };

export type GetAdminSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminSettingsQuery = { __typename?: 'Query', getAdminSettings: Array<{ __typename?: 'AdminSettings', id: string, name: string, type: string, data: any }> };

export type GetAdminSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAdminSettingsByIdQuery = { __typename?: 'Query', getAdminSettingsById?: { __typename?: 'AdminSettings', id: string, name: string, type: string, data: any } | null };

export type UpsertAdminSettingsMutationVariables = Exact<{
  input: AdminSettingsInput;
}>;


export type UpsertAdminSettingsMutation = { __typename?: 'Mutation', upsertAdminSettings?: { __typename?: 'AdminSettings', id: string, name: string, type: string, data: any } | null };

export type DeleteAdminSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAdminSettingsMutation = { __typename?: 'Mutation', deleteAdminSettings?: boolean | null };

export type GetAllArtworksQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllArtworksQuery = { __typename?: 'Query', getAllArtworks: { __typename?: 'ArtworkPagination', total: number, hasMore: boolean, artworks: Array<{ __typename?: 'Artwork', id: string, name: string, type: string, dateStarted?: string | null, meta?: any | null, maker: { __typename?: 'Maker', id: string, name: string }, text?: Array<{ __typename?: 'LocalizedTextContent', locale: string, name?: { __typename?: 'FieldLengthContent', text?: Array<string | null> | null, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null, description?: { __typename?: 'FieldLengthContent', text?: Array<string | null> | null, audio?: Array<{ __typename?: 'Audio', voiceName?: string | null, url?: string | null } | null> | null } | null } | null> | null }> } };

export type GetAllMakersAndLocationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllMakersAndLocationsQuery = { __typename?: 'Query', getAllLocations: { __typename?: 'LocationPagination', total: number, hasMore: boolean, locations: Array<{ __typename?: 'Location', id: string, name: string, city?: string | null, country: string, type: string }> }, getAllMakers: { __typename?: 'MakerPagination', total: number, hasMore: boolean, makers: Array<{ __typename?: 'Maker', id: string, name: string, birthPlace?: string | null }> } };

export type CreateArtworkMutationVariables = Exact<{
  input: CreateArtworkInput;
}>;


export type CreateArtworkMutation = { __typename?: 'Mutation', createArtwork: { __typename?: 'Artwork', id: string, name: string, type: string, dateStarted?: string | null, meta?: any | null, maker: { __typename?: 'Maker', id: string }, location: { __typename?: 'Location', id: string }, text?: Array<{ __typename?: 'LocalizedTextContent', locale: string, name?: { __typename?: 'FieldLengthContent', text?: Array<string | null> | null } | null, description?: { __typename?: 'FieldLengthContent', text?: Array<string | null> | null } | null } | null> | null } };

export type GenerateSignedUploadUrlQueryVariables = Exact<{
  input: GenerateSignedUploadUrlInput;
}>;


export type GenerateSignedUploadUrlQuery = { __typename?: 'Query', generateSignedUploadUrl: { __typename?: 'SignedUploadUrl', signedUrl: string, fileName: string } };

export type ProcessUploadedMediaMutationVariables = Exact<{
  input: ProcessUploadedMediaInput;
}>;


export type ProcessUploadedMediaMutation = { __typename?: 'Mutation', processUploadedMedia: { __typename?: 'Media', id: string, url: string, type: MediaType, meta?: any | null, createdAt: string, updatedAt: string } };

export type LoginMutationVariables = Exact<{
  token: Scalars['String']['input'];
  source: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', id: string, name: string, email: string, avatar?: string | null, role: string, jwt: string } };

export const AdminSettingsFieldsFragmentDoc = gql`
    fragment AdminSettingsFields on AdminSettings {
  id
  name
  type
  data
}
    `;
export const GetAdminSettingsDocument = gql`
    query GetAdminSettings {
  getAdminSettings {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;

/**
 * __useGetAdminSettingsQuery__
 *
 * To run a query within a React component, call `useGetAdminSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
      }
export function useGetAdminSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
        }
export function useGetAdminSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
        }
export type GetAdminSettingsQueryHookResult = ReturnType<typeof useGetAdminSettingsQuery>;
export type GetAdminSettingsLazyQueryHookResult = ReturnType<typeof useGetAdminSettingsLazyQuery>;
export type GetAdminSettingsSuspenseQueryHookResult = ReturnType<typeof useGetAdminSettingsSuspenseQuery>;
export type GetAdminSettingsQueryResult = Apollo.QueryResult<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>;
export const GetAdminSettingsByIdDocument = gql`
    query GetAdminSettingsById($id: ID!) {
  getAdminSettingsById(id: $id) {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;

/**
 * __useGetAdminSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminSettingsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables> & ({ variables: GetAdminSettingsByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
      }
export function useGetAdminSettingsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
        }
export function useGetAdminSettingsByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
        }
export type GetAdminSettingsByIdQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdQuery>;
export type GetAdminSettingsByIdLazyQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdLazyQuery>;
export type GetAdminSettingsByIdSuspenseQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdSuspenseQuery>;
export type GetAdminSettingsByIdQueryResult = Apollo.QueryResult<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>;
export const UpsertAdminSettingsDocument = gql`
    mutation UpsertAdminSettings($input: AdminSettingsInput!) {
  upsertAdminSettings(input: $input) {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;
export type UpsertAdminSettingsMutationFn = Apollo.MutationFunction<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>;

/**
 * __useUpsertAdminSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertAdminSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAdminSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAdminSettingsMutation, { data, loading, error }] = useUpsertAdminSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAdminSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>(UpsertAdminSettingsDocument, options);
      }
export type UpsertAdminSettingsMutationHookResult = ReturnType<typeof useUpsertAdminSettingsMutation>;
export type UpsertAdminSettingsMutationResult = Apollo.MutationResult<UpsertAdminSettingsMutation>;
export type UpsertAdminSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>;
export const DeleteAdminSettingsDocument = gql`
    mutation DeleteAdminSettings($id: ID!) {
  deleteAdminSettings(id: $id)
}
    `;
export type DeleteAdminSettingsMutationFn = Apollo.MutationFunction<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>;

/**
 * __useDeleteAdminSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteAdminSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminSettingsMutation, { data, loading, error }] = useDeleteAdminSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdminSettingsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>(DeleteAdminSettingsDocument, options);
      }
export type DeleteAdminSettingsMutationHookResult = ReturnType<typeof useDeleteAdminSettingsMutation>;
export type DeleteAdminSettingsMutationResult = Apollo.MutationResult<DeleteAdminSettingsMutation>;
export type DeleteAdminSettingsMutationOptions = Apollo.BaseMutationOptions<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>;
export const GetAllArtworksDocument = gql`
    query GetAllArtworks($page: Int, $limit: Int, $lang: String) {
  getAllArtworks(page: $page, limit: $limit, lang: $lang) {
    artworks {
      id
      name
      type
      maker {
        id
        name
      }
      dateStarted
      meta
      text {
        locale
        name {
          text
          audio {
            voiceName
            url
          }
        }
        description {
          text
          audio {
            voiceName
            url
          }
        }
      }
    }
    total
    hasMore
  }
}
    `;

/**
 * __useGetAllArtworksQuery__
 *
 * To run a query within a React component, call `useGetAllArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArtworksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetAllArtworksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllArtworksQuery, GetAllArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllArtworksQuery, GetAllArtworksQueryVariables>(GetAllArtworksDocument, options);
      }
export function useGetAllArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllArtworksQuery, GetAllArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllArtworksQuery, GetAllArtworksQueryVariables>(GetAllArtworksDocument, options);
        }
export function useGetAllArtworksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllArtworksQuery, GetAllArtworksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllArtworksQuery, GetAllArtworksQueryVariables>(GetAllArtworksDocument, options);
        }
export type GetAllArtworksQueryHookResult = ReturnType<typeof useGetAllArtworksQuery>;
export type GetAllArtworksLazyQueryHookResult = ReturnType<typeof useGetAllArtworksLazyQuery>;
export type GetAllArtworksSuspenseQueryHookResult = ReturnType<typeof useGetAllArtworksSuspenseQuery>;
export type GetAllArtworksQueryResult = Apollo.QueryResult<GetAllArtworksQuery, GetAllArtworksQueryVariables>;
export const GetAllMakersAndLocationsDocument = gql`
    query GetAllMakersAndLocations($page: Int, $limit: Int, $lang: String) {
  getAllLocations(page: $page, limit: $limit) {
    locations {
      id
      name
      city
      country
      type
    }
    total
    hasMore
  }
  getAllMakers(page: $page, limit: $limit, lang: $lang) {
    makers {
      id
      name
      birthPlace
    }
    total
    hasMore
  }
}
    `;

/**
 * __useGetAllMakersAndLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllMakersAndLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMakersAndLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMakersAndLocationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetAllMakersAndLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>(GetAllMakersAndLocationsDocument, options);
      }
export function useGetAllMakersAndLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>(GetAllMakersAndLocationsDocument, options);
        }
export function useGetAllMakersAndLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>(GetAllMakersAndLocationsDocument, options);
        }
export type GetAllMakersAndLocationsQueryHookResult = ReturnType<typeof useGetAllMakersAndLocationsQuery>;
export type GetAllMakersAndLocationsLazyQueryHookResult = ReturnType<typeof useGetAllMakersAndLocationsLazyQuery>;
export type GetAllMakersAndLocationsSuspenseQueryHookResult = ReturnType<typeof useGetAllMakersAndLocationsSuspenseQuery>;
export type GetAllMakersAndLocationsQueryResult = Apollo.QueryResult<GetAllMakersAndLocationsQuery, GetAllMakersAndLocationsQueryVariables>;
export const CreateArtworkDocument = gql`
    mutation CreateArtwork($input: CreateArtworkInput!) {
  createArtwork(input: $input) {
    id
    name
    type
    maker {
      id
    }
    location {
      id
    }
    dateStarted
    meta
    text {
      locale
      name {
        text
      }
      description {
        text
      }
    }
  }
}
    `;
export type CreateArtworkMutationFn = Apollo.MutationFunction<CreateArtworkMutation, CreateArtworkMutationVariables>;

/**
 * __useCreateArtworkMutation__
 *
 * To run a mutation, you first call `useCreateArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtworkMutation, { data, loading, error }] = useCreateArtworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArtworkMutation(baseOptions?: Apollo.MutationHookOptions<CreateArtworkMutation, CreateArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArtworkMutation, CreateArtworkMutationVariables>(CreateArtworkDocument, options);
      }
export type CreateArtworkMutationHookResult = ReturnType<typeof useCreateArtworkMutation>;
export type CreateArtworkMutationResult = Apollo.MutationResult<CreateArtworkMutation>;
export type CreateArtworkMutationOptions = Apollo.BaseMutationOptions<CreateArtworkMutation, CreateArtworkMutationVariables>;
export const GenerateSignedUploadUrlDocument = gql`
    query GenerateSignedUploadUrl($input: GenerateSignedUploadUrlInput!) {
  generateSignedUploadUrl(input: $input) {
    signedUrl
    fileName
  }
}
    `;

/**
 * __useGenerateSignedUploadUrlQuery__
 *
 * To run a query within a React component, call `useGenerateSignedUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSignedUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSignedUploadUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSignedUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables> & ({ variables: GenerateSignedUploadUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>(GenerateSignedUploadUrlDocument, options);
      }
export function useGenerateSignedUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>(GenerateSignedUploadUrlDocument, options);
        }
export function useGenerateSignedUploadUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>(GenerateSignedUploadUrlDocument, options);
        }
export type GenerateSignedUploadUrlQueryHookResult = ReturnType<typeof useGenerateSignedUploadUrlQuery>;
export type GenerateSignedUploadUrlLazyQueryHookResult = ReturnType<typeof useGenerateSignedUploadUrlLazyQuery>;
export type GenerateSignedUploadUrlSuspenseQueryHookResult = ReturnType<typeof useGenerateSignedUploadUrlSuspenseQuery>;
export type GenerateSignedUploadUrlQueryResult = Apollo.QueryResult<GenerateSignedUploadUrlQuery, GenerateSignedUploadUrlQueryVariables>;
export const ProcessUploadedMediaDocument = gql`
    mutation ProcessUploadedMedia($input: ProcessUploadedMediaInput!) {
  processUploadedMedia(input: $input) {
    id
    url
    type
    meta
    createdAt
    updatedAt
  }
}
    `;
export type ProcessUploadedMediaMutationFn = Apollo.MutationFunction<ProcessUploadedMediaMutation, ProcessUploadedMediaMutationVariables>;

/**
 * __useProcessUploadedMediaMutation__
 *
 * To run a mutation, you first call `useProcessUploadedMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessUploadedMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processUploadedMediaMutation, { data, loading, error }] = useProcessUploadedMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessUploadedMediaMutation(baseOptions?: Apollo.MutationHookOptions<ProcessUploadedMediaMutation, ProcessUploadedMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessUploadedMediaMutation, ProcessUploadedMediaMutationVariables>(ProcessUploadedMediaDocument, options);
      }
export type ProcessUploadedMediaMutationHookResult = ReturnType<typeof useProcessUploadedMediaMutation>;
export type ProcessUploadedMediaMutationResult = Apollo.MutationResult<ProcessUploadedMediaMutation>;
export type ProcessUploadedMediaMutationOptions = Apollo.BaseMutationOptions<ProcessUploadedMediaMutation, ProcessUploadedMediaMutationVariables>;
export const LoginDocument = gql`
    mutation Login($token: String!, $source: String!, $id: String) {
  login(token: $token, source: $source, id: $id) {
    id
    name
    email
    avatar
    role
    jwt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *      source: // value for 'source'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;