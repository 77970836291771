import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import LogoutButton from '../components/LogoutButton';
import LanguageSettings from '../components/transLangSelector';
import { RootState } from '../store/store';

const SettingsPage = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);

  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <div className="my-5">
        <LanguageSettings />
      </div>

      {auth.email !== '' ? (
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="my-5 d-flex justify-content-between align-items-center">
              <div>
                Logged in as:<br></br>
                {auth.email}
              </div>
              <div>
                <LogoutButton />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SettingsPage;
