import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';

import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';

import {
  AdminSettings,
  AdminSettingsInput,
  useDeleteAdminSettingsMutation,
  useGetAdminSettingsQuery,
  useUpsertAdminSettingsMutation,
} from '../../graphql/server-graphql-schema';

const AdminSettingsForm: React.FC = () => {
  const [formData, setFormData] = useState<AdminSettingsInput>({ name: '', type: '', data: {} });
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);

  const { data, loading: loadingSettings, refetch } = useGetAdminSettingsQuery();
  const [deleteAdminSettings] = useDeleteAdminSettingsMutation();
  const [upsertAdminSettings] = useUpsertAdminSettingsMutation();

  useEffect(() => {
    if (selectedId && data?.getAdminSettings && data?.getAdminSettings.length > 0) {
      const selectedSetting = data?.getAdminSettings.find((setting: AdminSettings) => setting.id === selectedId);
      if (selectedSetting) {
        setFormData({
          id: selectedSetting.id,
          name: selectedSetting.name,
          type: selectedSetting.type,
          data: selectedSetting.data,
        });
      }
    }
  }, [selectedId, data]);

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleJsonChange = (newValue: string) => {
    try {
      const jsonData = JSON.parse(newValue);
      setFormData((prev) => ({ ...prev, data: jsonData }));
    } catch (error) {
      console.error('Invalid JSON:', error);
    }
  };

  const handleSubmit = async () => {
    setLoadingSave(true);
    const input: AdminSettingsInput = {
      ...formData,
      id: selectedId,
    };

    if (!input.id) {
      delete input.id;
    }

    try {
      await upsertAdminSettings({
        variables: {
          input,
        },
      });
      await refetch();
    } catch (error) {
      console.error('Error saving settings:', error);
    }

    setLoadingSave(false);
    setSelectedId(null);
    setFormData({ name: '', type: '', data: {} });
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteAdminSettings({
        variables: { id },
      });
      await refetch();
    } catch (error) {
      console.error('Error deleting settings:', error);
    }
  };

  const handleClear = () => {
    setFormData({ id: null, name: '', type: '', data: {} });
  };

  return (
    <div className="container mt-4">
      <Form>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" autoComplete="off" value={formData.name} onChange={handleFormChange} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formType">
              <Form.Label>Type</Form.Label>
              <Form.Select name="type" value={formData.type} autoComplete="off" onChange={handleFormChange}>
                <option value="">Select Type</option>
                <option value="Prompts">Prompts</option>
                <option value="Client">Client</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <CodeMirror
              id="formData"
              extensions={[json()]}
              onChange={handleJsonChange}
              value={JSON.stringify(formData.data, null, 4)}
              height="100px"
            />

            <div className="mt-4">
              <Button variant="primary" onClick={handleSubmit} disabled={loadingSave || loadingSettings}>
                {loadingSave ? 'Saving...' : 'Save'}
              </Button>{' '}
              <Button variant="secondary" onClick={handleClear} disabled={loadingSave || loadingSettings}>
                {loadingSave ? 'Saving...' : 'New'}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      {loadingSettings && <Spinner animation="border" role="status" />}

      {!loadingSettings && data?.getAdminSettings && (
        <div className="mt-4">
          <h3 className="mt-4">Settings List</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.getAdminSettings.map((setting) => (
                <tr key={setting.id}>
                  <td>{setting.name}</td>
                  <td>{setting.type}</td>
                  <td>
                    <Button size="sm" onClick={() => setSelectedId(setting.id)}>
                      Edit
                    </Button>{' '}
                    <Button variant="danger" size="sm" onClick={() => handleDelete(setting.id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default AdminSettingsForm;
