import React from 'react';
import Container from 'react-bootstrap/Container';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';

import NavBarComponent from './components/NavBarComponent';
import { apolloClient } from './graphql/ApolloClientSetup';
import { ToastProvider } from './hooks/ToastNotificationProvider';
import PublicRoutes from './pages/routes/PublicRoutes';
import { persistore, store } from './store/store';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ToastProvider>
          <PersistGate loading={null} persistor={persistore}>
            <ApolloProvider client={apolloClient}>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <Container fluid>
                  <BrowserRouter
                    future={{ v7_relativeSplatPath: true, v7_startTransition: true }} // Enable both future flags
                  >
                    <NavBarComponent />
                    <PublicRoutes />
                  </BrowserRouter>
                </Container>
              </GoogleOAuthProvider>
            </ApolloProvider>
          </PersistGate>
        </ToastProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
