import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputGroup, ListGroup } from 'react-bootstrap';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type AutocompleteSelectProps = {
  options: { id: string; name: string }[];
  placeholder?: string;
  onSelect: (selected: { id: string; name: string }) => void;
};

const MAX_ITEMS = 7; // Define the maximum number of items to return

const AutocompleteSelect: React.FC<AutocompleteSelectProps> = ({ options, placeholder, onSelect }) => {
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{ id: string; name: string } | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  // Function to filter options based on query
  const filterOptions = (input: string) => {
    const parts = input.trim().toLowerCase().split(/\s+/); // Split input by spaces
    let matches: { id: string; name: string }[] = [];

    if (parts.length > 0) {
      matches = options.filter((option) => parts.some((part) => option.name.toLowerCase().includes(part)));

      if (matches.length > MAX_ITEMS) {
        matches = options.filter((option) => parts.every((part) => option.name.toLowerCase().includes(part)));
      }
    }

    return matches.slice(0, MAX_ITEMS); // Limit to MAX_ITEMS
  };

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);

    if (value.trim() === '') {
      setFilteredOptions(options.slice(0, MAX_ITEMS)); // Default to top results
    } else {
      setFilteredOptions(filterOptions(value));
    }

    setShowOptions(true);
  };

  // Handle option selection
  const handleOptionSelect = (option: { id: string; name: string }) => {
    setQuery(option.name);
    setShowOptions(false);
    setSelectedOption(option);
    onSelect(option);
  };

  // Clear input field
  const clearInput = () => {
    setQuery('');
    setFilteredOptions(options.slice(0, MAX_ITEMS)); // Reset options to default top results
    setShowOptions(false);
    setSelectedOption(null);
  };

  // Handle focus on input
  const handleFocus = () => {
    setShowOptions(true);
  };

  // Handle click outside to collapse dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowOptions(false);
        if (!selectedOption) {
          setQuery('');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedOption]);

  return (
    <div ref={containerRef} className="position-relative w-100">
      <InputGroup className="w-100" style={{ position: 'relative' }}>
        <FormControl
          type="text"
          placeholder={placeholder || 'Start typing...'}
          value={query}
          onChange={handleInputChange}
          onFocus={handleFocus}
          style={{ width: '100%' }}
        />
        <div style={styles.clearButton} onClick={clearInput} role="button" aria-label="Clear Input">
          <FontAwesomeIcon icon={faXmark} size="lg" />
        </div>
      </InputGroup>
      {showOptions && (
        <ListGroup className="position-absolute w-100" style={styles.dropdown}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <ListGroup.Item key={option.id} action onClick={() => handleOptionSelect(option)}>
                {option.name}
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No options found</ListGroup.Item>
          )}
        </ListGroup>
      )}
    </div>
  );
};

export default AutocompleteSelect;

const styles = {
  dropdown: {
    maxHeight: '200px',
    overflowY: 'auto' as 'auto',
    zIndex: 1050,
    background: 'white',
    border: '1px solid #ccc',
  },
  clearButton: {
    position: 'absolute' as 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    color: '#aaa',
    cursor: 'pointer',
    zIndex: 10,
  },
};
