import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import AutocompleteSelect from '../../components/AutocompleteSelect';
import { useCreateArtworkMutation, useGetAllMakersAndLocationsQuery } from '../../graphql/server-graphql-schema';

type LocalizedText = {
  locale: string;
  name: { text: string[] };
  description: { text: string[] };
};

const CreateArtworkPage: React.FC = () => {
  const [name, setName] = useState('');
  const [type, setType] = useState('PAINTING');
  const [makerId, setMakerId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [dateStarted, setDateStarted] = useState('');
  const [meta, setMeta] = useState('');
  const [localizedTexts, setLocalizedTexts] = useState<LocalizedText[]>([
    {
      locale: 'en',
      name: { text: ['', '', '', ''] },
      description: { text: ['', '', '', ''] },
    },
  ]);

  const navigate = useNavigate();
  const { data, loading } = useGetAllMakersAndLocationsQuery({
    variables: { page: 1, limit: 100, lang: 'en' },
  });

  const [createArtworkMutation] = useCreateArtworkMutation();

  const handleCreateArtwork = async () => {
    try {
      const parsedMeta = meta ? JSON.parse(meta) : null;
      await createArtworkMutation({
        variables: {
          input: {
            name,
            type,
            makerId,
            locationId,
            dateStarted: dateStarted || null,
            meta: parsedMeta,
            text: localizedTexts.map(({ locale, name, description }) => ({
              locale,
              name: { text: name.text },
              description: { text: description.text },
            })),
          },
        },
      });
      navigate('/artworks');
    } catch (error) {
      console.error('Error creating artwork:', error);
      alert('Failed to create artwork. Please check your input and try again.');
    }
  };

  const addLocalizedText = () => {
    setLocalizedTexts([
      ...localizedTexts,
      { locale: '', name: { text: ['', '', '', ''] }, description: { text: ['', '', '', ''] } },
    ]);
  };

  const updateLocale = (index: number, locale: string) => {
    const updatedTexts = [...localizedTexts];
    updatedTexts[index].locale = locale;
    setLocalizedTexts(updatedTexts);
  };

  const updateTextField = (index: number, field: 'name' | 'description', textIndex: number, value: string) => {
    const updatedTexts = [...localizedTexts];
    updatedTexts[index][field].text[textIndex] = value;
    setLocalizedTexts(updatedTexts);
  };

  return (
    <Container className="mt-5">
      <h2>Create New Artwork</h2>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          void handleCreateArtwork();
        }}
      >
        <Card className="mb-4 p-3">
          <Card.Title>Select Maker and Location</Card.Title>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="formMakerId">
                <Form.Label>Maker</Form.Label>
                <AutocompleteSelect
                  options={
                    !loading && data?.getAllMakers?.makers
                      ? data.getAllMakers.makers.map((maker) => ({ id: maker.id, name: maker.name }))
                      : []
                  }
                  placeholder="Type to search makers..."
                  onSelect={(selected) => setMakerId(selected.id)}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <Form.Group controlId="formLocationId">
                <Form.Label>Location</Form.Label>
                <AutocompleteSelect
                  options={
                    !loading && data?.getAllLocations?.locations
                      ? data.getAllLocations.locations.map((location) => ({ id: location.id, name: location.name }))
                      : []
                  }
                  placeholder="Type to search locations..."
                  onSelect={(selected) => setLocationId(selected.id)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card>

        <Card className="mb-4 p-3">
          <Card.Title>Basic Information</Card.Title>
          <Form.Group controlId="formArtworkName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          </Form.Group>

          <Form.Group controlId="formArtworkType" className="mt-2">
            <Form.Label>Type</Form.Label>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)} required>
              <option value="PAINTING">Painting</option>
              <option value="SCULPTURE">Sculpture</option>
              <option value="INSTALLATION">Installation</option>
              <option value="OTHER">Other</option>
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="formDateStarted" className="mt-2">
            <Form.Label>Date Started</Form.Label>
            <Form.Control
              type="text"
              placeholder="YYYY-MM-DD"
              value={dateStarted}
              onChange={(e) => setDateStarted(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formMeta" className="mt-2">
            <Form.Label>Meta (JSON)</Form.Label>
            <Form.Control as="textarea" placeholder='{"key": "value"}' value={meta} onChange={(e) => setMeta(e.target.value)} />
          </Form.Group>
        </Card>

        <Card className="mb-4 p-3">
          <Card.Title>Localized Texts</Card.Title>
          {localizedTexts.map((text, index) => (
            <Row key={index} className="mb-3">
              <Col>
                <Form.Group controlId={`formLocalizedTextLanguage${index}`}>
                  <Form.Label>Locale</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g., en"
                    value={text.locale}
                    onChange={(e) => updateLocale(index, e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={`formLocalizedTextName${index}`}>
                  <Form.Label>Name Texts</Form.Label>
                  {text.name.text.map((nameText, textIndex) => (
                    <Form.Control
                      key={textIndex}
                      type="text"
                      placeholder={`Name Text ${textIndex + 1}`}
                      value={nameText}
                      onChange={(e) => updateTextField(index, 'name', textIndex, e.target.value)}
                      className="mb-2"
                    />
                  ))}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId={`formLocalizedTextDescription${index}`}>
                  <Form.Label>Description Texts</Form.Label>
                  {text.description.text.map((descText, textIndex) => (
                    <Form.Control
                      key={textIndex}
                      type="text"
                      placeholder={`Description Text ${textIndex + 1}`}
                      value={descText}
                      onChange={(e) => updateTextField(index, 'description', textIndex, e.target.value)}
                      className="mb-2"
                    />
                  ))}
                </Form.Group>
              </Col>
            </Row>
          ))}
          <Button variant="link" onClick={addLocalizedText}>
            + Add Locale
          </Button>
        </Card>

        <div className="d-flex justify-content-between">
          <Button
            variant="secondary"
            onClick={() =>
              setLocalizedTexts([
                { locale: 'en', name: { text: ['Sample Artwork'] }, description: { text: ['Sample Description'] } },
              ])
            }
          >
            Use Sample Data
          </Button>
          <Button variant="primary" type="submit">
            Create Artwork
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CreateArtworkPage;
