import { Route, Routes } from 'react-router-dom';

import AdminSettingsManagementPage from '../AdminSettings/AdminSettingsManagementPage';
import ArtworkListPage from '../artwork/artworkListPage';
import CreateArtworkPage from '../artwork/createArtworkPage';
import HomePage from '../HomePage';
import LoginPage from '../LoginPage';
import PrivacyPage from '../PrivacyPage';
import SettingsPage from '../SettingsPage';
import TOUPage from '../TOUPage';

const PublicRoutes = () => (
  <Routes>
    <Route path="/appsettings" element={<AdminSettingsManagementPage />} />
    <Route path="/settings" element={<SettingsPage />} />

    <Route path="/artworks" element={<ArtworkListPage />} />
    <Route path="/create-artwork" element={<CreateArtworkPage />} />

    <Route path="/login" element={<LoginPage />} />
    <Route path="/privacy" element={<PrivacyPage />} />
    <Route path="/tou" element={<TOUPage />} />
    <Route path="/" element={<HomePage />} />
    <Route path="" element={<HomePage />} />
    <Route path="*" element={<p>Path not resolved</p>} />
  </Routes>
);

export default PublicRoutes;
