import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyPage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy</title>
      </Helmet>
      <div className="container pt-5">
        <div className="col-12">
          <div className="row">
            <h2>Privacy Statement for Art Hista</h2>
            <p>
              <strong>Effective Date: Nov 1, 2023</strong>
            </p>

            <h3>Introduction</h3>
            <p>
              Welcome to our Language Learning App. We are committed to protecting your privacy and ensuring a secure online
              experience. This Privacy Statement outlines our practices regarding the collection, use, and disclosure of
              information through our app.
            </p>

            <h3>Information Collection</h3>
            <p>
              Our app is designed to facilitate language learning without collecting any personally identifiable information (PII)
              from our users.
            </p>

            <h4>What We Don't Collect:</h4>
            <ul>
              <li>
                We do not collect physical addresses, phone numbers, or any other personal data that could be used to directly
                identify you.
              </li>
              <li>We do not track or store your location information.</li>
              <li>
                We do not collect sensitive information such as payment details, as our app is free to use and does not include
                in-app purchases.
              </li>
            </ul>

            <h4>Usage Data:</h4>
            <ul>
              <li>
                We collect anonymized usage data strictly for improving app functionality and user experience. This includes data
                like frequently accessed sections, preferred language pairs, and general app usage patterns.
              </li>
              <li>All usage data is fully anonymized and cannot be traced back to any individual user.</li>
            </ul>

            <h3>Data Use and Sharing</h3>
            <p>
              The anonymized data we collect is used solely for the purpose of enhancing app performance, fixing bugs, and
              developing new features. We do not share, sell, rent, or trade any anonymized data with third parties for their
              commercial purposes.
            </p>

            <h3>Data Security</h3>
            <p>
              We employ industry-standard security measures to protect the data we collect. Regular audits and updates are
              conducted to ensure the ongoing security and integrity of our systems.
            </p>

            <h3>Children's Privacy</h3>
            <p>
              Our app is suitable for users of all ages. However, we do not knowingly collect or maintain data from children under
              the age of 13.
            </p>

            <h3>Changes to this Privacy Statement</h3>
            <p>
              We may update this Privacy Statement periodically. The effective date at the top of the Privacy Statement will be
              updated accordingly. We encourage users to review this statement regularly to stay informed about our privacy
              practices.
            </p>

            <h3>Contact Us</h3>
            <p>
              For any questions or concerns regarding this Privacy Statement or our privacy practices, please contact us at{' '}
              <a href="mailto:support@arthista.com">Email Support: support@arthista.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
