import './App.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if ((module as any).hot) {
  (module as any).hot.accept(() => {
    console.clear();
    console.log('>>>reloaded');
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
