import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Auth {
  email: string;
  id: string;
  avatar: string;
  jwt: string;
  role: string;
}
interface AuthState {
  auth: Auth;
}

const initialState: AuthState = {
  auth: { email: '', id: '', avatar: '', jwt: '', role: 'user' },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.auth = { ...state.auth, ...action.payload };
    },
    clearAuth: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, clearAuth } = authSlice.actions;

export default authSlice.reducer;
